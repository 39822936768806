import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import {
  Root,
  Container,
  Header,
  Title,
  // SearchForm,
  // SearchInput,
  // SearchButton,
  Items,
  StampImg,
  Item,
  // ImgLink,
  ItemImg,
  // ItemBody,
  ItemTitle,
  // ItemDesc,
  ItemLink,
  StampImg2,
} from "./Recipes.style";

// import Pagination from "../Pagination/Pagination";

// import Recipe1Src from "../../images/recipe1.jpg";
// import Recipe2Src from "../../images/recipe2.jpg";
// import Recipe3Src from "../../images/recipe3.jpg";
// import Recipe4Src from "../../images/recipe4.jpg";

import StampSrc from "../../images/stamp-beige.svg";

const Recipes = ({recipes}) => {
  return (
    <Root>
      <Container>
        <Header>
          <Title>Recipes</Title>
          {/* <SearchForm>
            <SearchInput type="text" name="find" placeholder="Find a Recipe" />
            <SearchButton>
              <svg
                width="21px"
                height="21px"
                viewBox="0 0 21 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#ce9b61"
                  d="M 16.188 13.971 C 16.254 14.008 16.318 14.053 16.378 14.103 C 17.757 15.478 19.134 16.857 20.51 18.236 C 20.957 18.646 21.115 19.285 20.914 19.856 C 20.747 20.448 20.246 20.884 19.637 20.972 C 19.13 21.074 18.605 20.904 18.249 20.526 C 16.867 19.143 15.484 17.763 14.1 16.381 C 14.05 16.321 14.009 16.254 13.977 16.184 C 8.37 20.101 0.623 16.479 0.034 9.663 C -0.554 2.848 6.456 -2.049 12.653 0.849 C 17.641 3.182 19.329 9.445 16.188 13.968 Z M 8.881 14.546 C 13.23 14.573 15.978 9.882 13.826 6.103 C 12.828 4.348 10.97 3.26 8.949 3.247 C 4.602 3.221 1.855 7.911 4.006 11.692 C 5.005 13.447 6.863 14.534 8.881 14.546 Z"
                />
              </svg>
            </SearchButton>
          </SearchForm> */}
        </Header>

        <StampImg src={StampSrc} alt="" />
        <Items>
          {recipes.map(itm => (
            <Item>
              <a as={Link} href={"/recipe/?id="+itm.id} >
              <ItemImg src={itm.thumbnail} alt="" />
              <ItemTitle>{itm.title}</ItemTitle>
              </a>
            </Item>
          ))}

          {/* <Item>
            <ImgLink as={Link} to="/">
              <ItemImg src={Recipe1Src} alt="" />
            </ImgLink>
            <ItemBody>
              <Link to="/recipe">
                <ItemTitle>OLD-FASHIONED</ItemTitle>
              </Link>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink as={Link} to="/recipe">
                Read more
              </ItemLink>
            </ItemBody>
          </Item>

          <Item>
            <ImgLink as={Link} to="/">
              <ItemImg src={Recipe2Src} alt="" />
            </ImgLink>
            <ItemBody>
              <Link to="/recipe">
                <ItemTitle>WHISKEY SOUR</ItemTitle>
              </Link>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink as={Link} to="/recipe">
                Read more
              </ItemLink>
            </ItemBody>
          </Item>

          <Item>
            <ImgLink as={Link} to="/">
              <ItemImg src={Recipe3Src} alt="" />
            </ImgLink>
            <ItemBody>
              <Link to="/recipe">
                <ItemTitle>HIGHBALL</ItemTitle>
              </Link>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink as={Link} to="/recipe">
                Read more
              </ItemLink>
            </ItemBody>
          </Item>

          <Item>
            <ImgLink as={Link} to="/">
              <ItemImg src={Recipe4Src} alt="" />
            </ImgLink>
            <ItemBody>
              <Link to="/recipe">
                <ItemTitle>SAZERAC</ItemTitle>
              </Link>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink as={Link} to="/recipe">
                Get recipe
              </ItemLink>
            </ItemBody>
          </Item> */}
        </Items>

        {/* <Pagination /> */}
      </Container>
    </Root>
  );
};

Recipes.propTypes = {
  recipes: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.any.isRequired,
      thumbnail: PropTypes.any.isRequired,
      description: PropTypes.string,
      ingredients: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string
      })),
      steps: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        thumbnail: PropTypes.any
      })),
    }
  ))
};

export default Recipes;
